"use client"

import { type User } from "@prisma/client"
import { useState } from "react"
import { UserContext, UserContextProps } from "./UserContext"

export const MockUserProvider = ({ children }: UserContextProps) => {
  const [user] = useState<User | null>(null)

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
