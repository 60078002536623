"use client"

import dynamic from "next/dynamic"
import type { WorkmapsState } from "@/types"
import { createContext } from "react"
import { TooltipProvider } from "@/components/ui/tooltip"
import { MapProvider } from "./MapProvider"
import { MockUserProvider } from "./MockUserProvider"
import { PortalProvider } from "./PortalProvider"
import { ParamsProvider } from "./ParamsProvider"
import { QueryProvider } from "./QueryProvider"
import { StatsigProvider, type StatsigProviderProps } from "./StatsigProvider"
import { UserProvider } from "./UserProvider"
import { Toaster } from "@/components/ui/toaster"

export const WorkmapsContext = createContext<WorkmapsState | undefined>(undefined)
// This will lazy load Segment until after the app kicks off doing things
const AnalyticsProvider = dynamic(
  () => import("./AnalyticsProvider").then(({ AnalyticsProvider }) => AnalyticsProvider),
  { ssr: false }
)

type ContextProvidersProps = React.PropsWithChildren<StatsigProviderProps>

export const ContextProviders: React.FC<ContextProvidersProps> = ({ children, statsigValues, statsigUser }) => {
  const EffectiveUserProvider = process.env.STORYBOOK_ENVIRONMENT ? MockUserProvider : UserProvider

  return (
    <QueryProvider>
      <ParamsProvider>
        <EffectiveUserProvider>
          <StatsigProvider statsigValues={statsigValues} statsigUser={statsigUser}>
            <AnalyticsProvider>
              <PortalProvider>
                <TooltipProvider>
                  <Toaster />
                  <MapProvider>{children}</MapProvider>
                </TooltipProvider>
              </PortalProvider>
            </AnalyticsProvider>
          </StatsigProvider>
        </EffectiveUserProvider>
      </ParamsProvider>
    </QueryProvider>
  )
}
