"use client"

import { useCallback, useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import type { User, Session } from "@prisma/client"
import { api } from "@/lib/api"
import type { ApiResponse } from "@/types"

import { UserContext, UserContextProps } from "./UserContext"
import {
  SessionContext,
  type ISessionContext,
  type SuccessCallback,
  type SupportedLoginProvider,
} from "./SessionContext"
import { useParams } from "@/components/ParamsProvider"

export const UserProvider = ({ children }: UserContextProps) => {
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: ["user+session"],
    initialData: null,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const resp = await api.get("/api/users/me")
      const data = await resp.json<ApiResponse<{ user: User | null; session: Session }>>()

      if (!data.ok) {
        throw new Error(data.error || "Unknown error")
      }

      return data
    },
  })

  // When the user clicks a login button, it may open in a new tab. This query will ping every 3 seconds for two minutes OR
  // until the user logs in. This will allow the original tab to refresh with the logged in user.
  const [loginState, setLoginState] = useState<{
    provider: SupportedLoginProvider | null
    successCallback: SuccessCallback | null
  }>({
    provider: null,
    successCallback: null,
  })

  useQuery({
    queryKey: ["user", "poll-login", loginState.provider] as const,
    enabled: !!loginState.provider,
    retry: (retry) => {
      if (retry > 80) {
        setLoginState({ provider: null, successCallback: null })
        return false
      }

      return true
    },
    refetchInterval: 2000,
    queryFn: async ({ queryKey: [, , provider], signal }) => {
      if (!provider) {
        return null
      }

      const resp = await api.get("/api/users/me", { signal })

      if (!resp.ok) {
        return null
      }

      const data = await resp.json<ApiResponse<{ user: User | null; session: Session }>>()

      if (!data.ok) {
        return null
      }

      if (data.user && data.session.authMethod === provider) {
        loginState.successCallback?.({ user: data.user, session: data.session })
        setLoginState({ provider: null, successCallback: null })
        queryClient.setQueryData(["user+session"], data)
      }

      return data
    },
  })

  const logoutMutation = useMutation({
    mutationKey: ["user", "logout"],
    mutationFn: async () => {
      const resp = await api.delete("/api/users/logout")
      const data = await resp.json<ApiResponse<{ ok: boolean }>>()

      if (!data.ok) {
        throw new Error(data.error || "Unknown error")
      }

      queryClient.setQueryData(["user+session"], null)
    },
  })

  // #region Login with email auth token
  // If the user has clicked any link pointing to the site from an email, it will have a query param of `eat`. If that
  // is the case, log the user in with it and remove the query parameter.
  //
  // TODO This would be great to do in the middleware so no client side code would be needed, but we cannot use Prisma
  // there yet, as it is "edge" code. In Next 15, I think they are going to let middlewares run as regular Node code,
  // which would allow us to move this there.
  const [params, { deleteParams }] = useParams()
  const emailAuthToken = params.get("eat")
  useQuery({
    queryKey: ["user", "login-with-email-auth-token", emailAuthToken] as const,
    enabled: !!emailAuthToken,
    queryFn: async ({ queryKey: [, , email_auth_token], signal }) => {
      if (!email_auth_token) {
        return null
      }

      const resp = await api.post("/api/users/login", {
        json: { email_auth_token: emailAuthToken },
        signal,
      })

      const data = await resp.json<ApiResponse<{ user: User; session: Session }>>()

      if (!data.ok) {
        throw new Error(data.error || "Unknown error")
      }

      queryClient.setQueryData(["user+session"], data)
      deleteParams(["eat"])

      return data.user
    },
  })

  const getLoginUrl = useCallback<ISessionContext["getLoginUrl"]>(({ provider, redirectTo, target }) => {
    const url = new URL(`/api/users/login/${provider}`, window.location.href)

    if (redirectTo) {
      url.searchParams.set("r", redirectTo)
    }

    if (target === "_popup") {
      url.searchParams.set("popup", "1")
    }

    return url.toString()
  }, [])

  const startLogin = useCallback<ISessionContext["startLogin"]>(async (provider, successCallback) => {
    setLoginState({ provider, successCallback: successCallback ?? null })
  }, [])

  return (
    <SessionContext.Provider
      value={{
        session: data?.session ?? null,
        startLogin,
        getLoginUrl,
        logout: logoutMutation.mutateAsync,
      }}
    >
      <UserContext.Provider value={data?.user ?? null}>{children}</UserContext.Provider>
    </SessionContext.Provider>
  )
}
