"use client"

import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useSearchParams, useRouter } from "next/navigation"
import { createContext, useContext, useState, useCallback } from "react"

export const ParamsContext = createContext<
  [
    URLSearchParams,
    {
      pushParams: (params: Record<string, string>, replace?: boolean) => void
      replaceParams: (params: Record<string, string>) => void
      deleteParams: (params: string[], replace?: boolean) => void
      back: () => void
      stack: URLSearchParams[]
    },
  ]
>([
  new URLSearchParams(),
  {
    pushParams: () => {},
    replaceParams: () => {},
    deleteParams: () => {},
    back: () => {},
    stack: [],
  },
])

export type ParamsProviderProps = {
  children: React.ReactNode
}

export const ParamsProvider = ({ children }: ParamsProviderProps) => {
  const initialParams = useSearchParams()!
  const router = useRouter()
  const currentUrl = useCurrentUrl({ includeSearch: false })
  const [stack, setParams] = useState<URLSearchParams[]>([initialParams])

  const params = stack[stack.length - 1]

  const back = useCallback(() => {
    if (stack.length > 1) {
      setParams(stack.slice(0, stack.length - 1))
      router.back()
    }
  }, [stack, router])

  const pushParams = useCallback(
    (paramsToPush: Record<string, string>, replace = false) => {
      const newParams = new URLSearchParams(params)
      Object.entries(paramsToPush).forEach(([key, value]) => {
        newParams.set(key, value)
      })
      setParams(replace ? [...stack.slice(0, stack.length - 1), newParams] : [...stack, newParams])
      router[replace ? "replace" : "push"](`${currentUrl}?${newParams.toString()}`)
    },
    [currentUrl, params, stack, router]
  )

  const replaceParams = useCallback(
    (paramsToPush: Record<string, string>) => {
      const newParams = new URLSearchParams(params)
      Object.entries(paramsToPush).forEach(([key, value]) => {
        newParams.set(key, value)
      })
      setParams([...stack.slice(0, stack.length - 1), newParams])
      router.replace(`${currentUrl}?${newParams.toString()}`)
    },
    [currentUrl, params, stack, router]
  )

  const deleteParams = useCallback(
    (paramsToDelete: string[], replace = false) => {
      const newParams = new URLSearchParams(params)
      paramsToDelete.forEach((param) => {
        newParams.delete(param)
      })
      setParams(replace ? [...stack.slice(0, stack.length - 1), newParams] : [...stack, newParams])
      router[replace ? "replace" : "push"](`${currentUrl}?${newParams.toString()}`)
    },
    [currentUrl, params, stack, router]
  )

  return (
    <ParamsContext.Provider value={[params, { pushParams, replaceParams, deleteParams, back, stack }]}>
      {children}
    </ParamsContext.Provider>
  )
}

export const useParams = () => {
  const result = useContext(ParamsContext)
  return result
}
